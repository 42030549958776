<template>
  <div class="wa__gallery__images">
    <div
      :class="['wa__gallery__image', { 'btn-reject': !image.verified_at }]"
      :style="{
        backgroundImage: `url('${image.thumbnail_w300}')`,
      }"
      class="model__image--image"
    ></div>
    <div class="wa__gallery__image__remove">
      <div class="wa__gallery__image__remove--text">
        <div class="mt-2" v-if="this.role == 'manager'">
          <block-button
            height="32"
            :text="image.verified_at ? 'reject' : 'approve'"
            class="btn-remove site__button width-auto w-100"
            :loading="approveLoading"
            @click.native="setVerify"
          ></block-button>
        </div>
        <!-- <div class="mt-2" v-if="this.role == 'manager'">
          <block-button
            height="32"
            :text="(image.pdf_verified_at ? 'remove from' : 'add to') + ' pdf'"
            class="btn-remove w-100 site__button width-auto"
            :loading="addToPdfLoading"
            @click.native="addToPdf"
          >
          </block-button>
        </div> -->
        <div class="mt-2">
          <block-button
            v-if="this.role == 'manager'"
            height="32"
            text="CROP FOR PDF"
            class="btn-remove w-100 site__button width-auto"
            @click.native="
              $_openModal('crop_gallery_image_for_pdf', { model: { ...image, modelId } })
            "
          >
          </block-button>
        </div>
        <div class="mt-2">
          <block-button
            height="32"
            text="remove"
            class="btn-remove w-100 site__button width-auto"
            @click.native="remove"
          ></block-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileRepository from "@/abstraction/repository/FileRepository";
import toast from "@/utils/toast";
const fileRepository = new FileRepository();
export default {
  props: {
    image: {},
    role: {
      default: "",
    },
    images: {},
    modelId: {},
  },
  data() {
    return {
      approveLoading: false,
      addToPdfLoading: false,
    };
  },
  methods: {
    async remove() {
      toast.question(this.$_trans("toast.del_gallery_img_item_msg"), this.$_trans("toast.del_gallery_img_item_title"), async () => {
        await fileRepository.destroy(this.image.id);
        const index = this.images.findIndex((x) => x.id === this.image.id);
        this.$delete(this.images, index);
      });
    },
    async addToPdf() {
      try {
        this.addToPdfLoading = true;
        const index = this.images.findIndex((x) => x.id === this.image.id);
        if (this.image.pdf_verified_at) {
          await fileRepository.pdfUnVerify(this.image.id);
          this.images[index].pdf_verified_at = null;
        } else {
          await fileRepository.pdfVerify(this.image.id);
          this.images[index].pdf_verified_at = this.todayTimeToString();
        }
      } catch (e) {
        return e;
      } finally {
        this.addToPdfLoading = false;
      }
    },
    async setVerify() {
      try {
        this.approveLoading = true;
        if (this.image.verified_at) {
          await fileRepository.unverifyImage(this.image.id);
          this.image.verified_at = null;
          return;
        } else {
          await fileRepository.verifyImage(this.image.id);
          this.image.verified_at = this.todayTimeToString();
        }
      } catch (e) {
        return e;
      } finally {
        this.approveLoading = false;
      }
    },
    todayTimeToString() {
      const today = new Date();
      return `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    },
  },
};
</script>
<style>
.wa__gallery__images {
  position: relative;
  height: 260px;
}
.wa__gallery__image {
  border-radius: 5px;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.wa__gallery__image__remove {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 90%;
}
.wa__gallery__images:hover .wa__gallery__image {
  filter: brightness(0.3);
}
.wa__gallery__images:hover .wa__gallery__image__remove {
  opacity: 1;
}
.btn-remove {
  padding: 0 25px !important;
  font-size: 17px !important;
}
.btn-reject {
  border: 1px solid red;
}
</style>
